.svg-tag {
  circle.node-circle {
    fill: transparent;
    stroke: var(--node-circle-stroke-color);
    stroke-width: 2.3px;
    pointer-events: none;    
  }
  text.icon-text {
    fill: var(--text-base-color);
    text-anchor: middle;
    font-size: 14px;
    pointer-events: none;    
  }
}

.bim-page {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  .drop-zone {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  svg.svg-bim {
    background-color: var(--svg-color);
    width: 100%;
    height: 100%;

    circle.node-circle {
      cursor: default;
      fill: transparent;
      stroke: var(--node-circle-stroke-color);
      stroke-width: 3px;
      &__highlighted {
        stroke: var(--path-highlight-color);
        cursor: pointer;
      }
      &__low {
        stroke: none;
      }
    }
    text.node-text {
      fill: var(--text-base-color);
      font-size: 6px;
      text-anchor: middle;
      pointer-events: none;
      &__highlighted {
        fill: var(--path-highlight-color);
      }
    }
    text.node-icon-text {
      fill: var(--text-base-color);
      text-anchor: middle;
      pointer-events: none;
      font-size: 12px;
    }
    circle.in-path-circle {
      pointer-events: none;
      fill: var(--indigo);
      stroke: none;
    }
    text.in-path-text {
      text-anchor: middle;
      font-size: 8px;
      fill: white;
      pointer-events: none;
    }
    line.ghost-line {
      stroke-dasharray: 3 1;
      stroke: var(--text-base-color);
      stroke-width: 1px;
    }

    circle.group-circle {
      stroke-dasharray: 10 3;
      stroke-width: 2px;
    }
    text.group-text {
      font-size: 14px;
      fill: var(--text-base-color);
      text-anchor: middle;
      pointer-events: none;
      font-weight: bold;
    }
  }

  .float-panel-zone {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    pointer-events: none;
    right: 8px;
  }
}
.list-group-item {
  background-color: var(--bg-color) !important;
  filter: brightness(0.75);
  color: var(--text-base-color) !important;
  &.active {
    filter: brightness(1);
    font-weight: bold;
  }
}

.tag-setting-modal {
  .nav-tabs {
    background-color: var(--bg-color) !important;
    color: var(--text-base-color) !important;
  }
  .nav-item {
    a.nav-link:hover, a.nav-link:focus {
      color: var(--text-base-color) !important;
    }
    .active {
      background-color: var(--bg-color) !important;
      color: var(--text-base-color) !important;
      filter: brightness(0.9);
    }
  }
  .tab-content {
    background-color: var(--bg-color) !important;
    color: var(--text-base-color) !important;
  }
}
