@keyframes change-button-size {
  from {
    width: 34px;
    height: 34px;
  }

  to {
    width: 38px;
    height: 38px;
  }
}
@keyframes change-icon-size {
  from {
    font-size: 16px;
  }

  to {
    font-size: 20px;
  }
}
.attack-path-viewer-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--svg-color) !important;
  svg.svg-attack-path-viewer {
    position: absolute;
    top: 0;
    circle.node-circle {
      cursor: default;
      fill: var(--svg-color);
      stroke: var(--node-circle-stroke-color);
      stroke-width: 4px;
      &__highlighted {
        stroke: var(--path-highlight-color);
        cursor: pointer;
      }
      &__hover_highlighted {
        stroke: var(--hover-highlight-node-color);
        cursor: pointer;
      }
      &__low {
        stroke: none;
      }
      &__depthest {
        fill: var(--depthest-node-circle-fill-color);
      }
      &__nest {
        fill: var(--nest-node-circle-fill-color);
      }
      &__child {
        fill: transparent;
      }
      &__on-highlighted {
        stroke: #DF9A33;
      }
      &__source-node {
        stroke: #4A82CD;
        stroke-width: 7px;
      }
      &__target-node {
        stroke-width: 7px;
        stroke: #D33F31;
      }
    }    
    circle.count-circle {
      fill: var(--pink-1);
      stroke: var(--black);
      stroke-width: 1px;
    }
    circle.indicator-circle {
      stroke: black;
      fill: var(--gray-200);
      stroke-width: 1px;
    }
    text.node-text {
      fill: var(--text-base-color);
      font-size: 9px;
      text-anchor: middle;
      pointer-events: none;
      &__highlighted {
        fill: var(--path-highlight-color);
      }
    }
    text.indicator-icon {
      fill: var(--text-base-color);
      cursor: pointer;
    }
    text.wrench-icon {
      fill: var(--text-base-color);
      cursor: pointer;
    }
    text.link-indicator-icon {
      fill: var(--svg-color);
      cursor: pointer;
    }
    circle.shadow-circle {
      fill: var(--card-bg-color);
    }
    circle.icon-ring-circle {
      stroke: var(--text-base-color);
      stroke-width: 1px;
      // stroke-linecap: round;
      // stroke-miterlimit: 10;
      // stroke-dasharray: 3 3;
      fill: transparent;
    }
    circle.icon-ring-fill-circle {
      fill: var(--text-base-color);
      stroke-width: none;
    }
    circle.ring-circle {
      stroke: var(--text-base-color);
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-dasharray: 5 5;
      fill: transparent;
    }
    text.icon-text {
      fill: var(--text-base-color);
      text-anchor: middle;
      pointer-events: none;
    }
    text.count-text {
      font-size: 9px;
      font-weight: bold;
      fill: white;
      pointer-events: none;
      text-anchor: middle;
    }
    text.indicator-text {
      fill: var(--dark);
      pointer-events: none;
      // text-anchor: middle;
      font-size: 9px;

    }
    text.group-icon {
      text-anchor: middle;
      fill: var(--text-base-color);
      pointer-events: none;
    }
    line.base-link {
      stroke: var(--text-base-color);
      stroke-width: 3px;
      &__highlighted {
        stroke: var(--path-highlight-color);
      }
      &__on-highlighted {
        stroke: #DF9A33;
      }
    }
    text.triangle {
      fill: lightgrey;
      text-anchor: middle;
      // font-size: 32px;
      &__highlighted {
        fill: var(--path-highlight-color);
      }
    }

    circle.circle-dot {
      stroke: var(--text-base-color);
      stroke-width: 1.5px;
      fill: var(--svg-color);
    }

    circle.link-dot {
      stroke: var(--text-base-color);
      stroke-width: 3px;
      fill: var(--svg-color);
    }

    text.exclamation-mark {
      fill: var(--text-base-color);
      stroke-width: 0.6px;
      stroke: var(--text-base-color)
    }
    
    text.wrench {
      text-anchor: middle;
      fill: var(--grey-850);
      pointer-events: none;
      font-size: 16px;
    }
    path.link-arrow {
      fill: none;
      stroke-width: 4px;
      stroke: var(--text-base-color);
      &__highlighted {
        stroke: var(--path-highlight-color);
      }
      &__on-highlighted {
        stroke: #DF9A33;
      }
    }
    text.link-desc {
      text-anchor: middle;
      fill: var(--text-base-color);
      font-weight: 500;
      font-size: 10px;
      font-style: italic;
      &__highlighted {
        fill: var(--path-highlight-color);
      }
      &__hover_highlighted {
        stroke: var(--hover-highlight-node-color);
        font-weight: 400;
        cursor: pointer;
      }
    }
  }

  .card-top-arrow {
    color: var(--light);
    pointer-events: none;
    z-index: 100;
  }
  .widget-card {
    border: none !important;
    background-color: var(--light) !important;
    color: var(--dark) !important;
    box-shadow: 0 3px 6px rgb(0 0 0 / 30%);
  }

  .path-symbol-popover {
    position: absolute;
    top: 8px;
    right: 8px;
    color: var(--text-base-color) !important;
  }
  .pulse-button {
    animation-duration: 1s;
    animation-name: change-button-size;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .pulse-button i {
    animation-duration: 1s;
    animation-name: change-icon-size;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

