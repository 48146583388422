:root {
  --black: #000000;
  --blue: #348fe2;
  --dark-blue: #35465e;
  --cyan: #49b6d6;
  --dark: #2d353c;  
  --dark-darker: #1a2229;
  --dark-1: #262b30;
  --green: #32a932;
  --green-1: #268226;
  --indigo: #8753de;
  --lime: #90ca4b;
  --light: #f2f3f4;
  --orange: #f59c1a;
  --purple: #727cb6;
  --pink: #fb5597;
  --red: #ff5b57;
  --silver: #b6c2c9;
  --teal: #00acac;
  --white: #ffffff;
  --yellow: #ffd900;
  --yellow-1: #ffff00;
  --inverse: --dark;
  --link-color: --blue;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;  
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --grey-850: #333333;
  --gray-900: --dark;

  
  --pink-1: #FF1493;
  --white-opacity-1: rgba(255, 255, 255, 0.1);
  --white-opacity-2: rgba(255, 255, 255, 0.5);

  --grey-opacity-1: rgba(220, 220, 220, 0.9);
  --grey-opacity-2: rgba(0, 0, 0, 0.2);
  --grey-opacity-3: rgba(0, 0, 0, 0.05);
  --grey-opacity-4: rgba(0, 0, 0, 0.7);
  --grey-opacity-5: rgba(200, 200, 200, 0.25);
  --blue-1: #478fd7;
  
  --bg-color: #1d2939;
  --svg-color: #101828;
    // "--header-bg-color": "var(--dark)",
  --text-base-color: #fff;
    // "--card-bg-color": "var(--dark-1)",
    // "--card-border-color": "transparent",
    // "--input-bg-color": "var(--gray-400)",
  --hover-highlight-node-color: #bdbdbd;
    // "--table-bg-color": "var(--dark-darker)",
    // "--table-even-row-color": "var(--grey-opacity-2)",
    // "--table-hover-color": "var(--white-opacity-1)",
    // "--table-loading-bg-color": "var(--grey-opacity-4)",
    // "--btn-save-color": "var(--light)",
    // "--tooltip-bg-color": "var(--light)",
    // "--tooltip-text-color": "var(--black)",
    // "--rogue-path-highlight-color": "var(--white)",
  --path-highlight-color: #ffee58;
  --node-circle-stroke-color: var(--gray-700);
  --depthest-node-circle-fill-color: var(--dark-blue); // color for depth 0 circle in attack path viewer
  --nest-node-circle-fill-color: var(--grey-opacity-5); // color for circles in depth 0 circle
    // "--configuration-border-color": "var(--light)",
}
